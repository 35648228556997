<!--
 * @Description: 报价预算
 * @Author: zhang zhen
 * @Date: 2023-02-16 09:58:58
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-02-16 13:42:35
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/BudgetPrice.vue
-->
<template>
  <div class="BudgetPrice">
    <div class="BudgetPrice-title">预算方案</div>
    <div class="BudgetPrice-content">
      <h3 class="header-title">总计</h3>
      <div class="inlineCharts" ref="inlineCharts_01"></div>
      <span class="totalNum large">总计：3200</span>
    </div>
    <div class="BudgetPrice-content">
      <h3 class="header-title">人工管理</h3>
      <div class="price-item normal">
        <span>仓库管理</span>
        <span>500</span>
      </div>
      <span class="totalNum">总计：500</span>
    </div>

    <div class="BudgetPrice-content">
      <h3 class="header-title">运输</h3>
      <div class="price-item normal">
        <span>物流</span>
        <span>200</span>
      </div>
      <span class="totalNum">总计：200</span>
    </div>
    <div class="BudgetPrice-content">
      <h3 class="header-title">材料</h3>
      <div class="price-item">
        <span>盒型</span>
        <span class="name">飞机盒</span>
        <span>500</span>
      </div>
      <div class="price-item">
        <span>运输方式</span>
        <span class="name">物流</span>
        <span>500</span>
      </div>
      <div class="price-item">
        <span>品牌</span>
        <span class="name">玖龙</span>
        <span>500</span>
      </div>
      <div class="price-item">
        <span>级别</span>
        <span class="name">AAA</span>
        <span>500</span>
      </div>
      <div class="price-item">
        <span>瓦楞层数</span>
        <span class="name">3层</span>
        <span>500</span>
      </div>
      <span class="totalNum">总计：500</span>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'BudgetPrice',
  data() {
    return {}
  },
  mounted() {
    this.handleDrawCharts()
  },
  methods: {
    handleDrawCharts() {
      const myCharts = echarts.init(this.$refs['inlineCharts_01'])
      myCharts.setOption({
        tooltip: {
          trigger: 'item'
        },
        title: {
          text: '飞机盒',
          textStyle: {
            color: '#090B12',
            fontSize: 14
          }
        },
        legend: {
          orient: 'vertical',
          top: 'middle',
          right: '20%',
          icon: 'circle',
          textStyle: {
            fontSize: 14
          }
        },
        color: ['#5e78fd', '#6d86fd', '#8ea0ff', '#aebbff'],
        series: [
          {
            name: '飞机盒',
            type: 'pie',
            radius: ['60%', '90%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 580, name: '管理' },
              { value: 484, name: '生产' },
              { value: 300, name: '材料' },
              { value: 1048, name: '包装' },
              { value: 735, name: '运输' }
            ]
          }
        ]
      })
    }
  }
}
</script>

<style lang="less" scoped>
.BudgetPrice {
  background: #fffff8;
  padding: 20px 60px;
  border-radius: 8px;
  &-title {
    color: #66676d;
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
    font-weight: bold;
  }
  &-content {
    width: 993px;
    border-radius: 8px;
    border: 1px dashed #bfbaba;
    padding: 40px 40px 34px;
    background: #fff;
    position: relative;
    margin: 15px auto 20px;
    .flexLayout(@justifyContent: space-around;@direction: column);
    .header-title {
      position: absolute;
      font-size: 15px;
      color: #605f5f;
      left: 20px;
      top: -10px;
    }
    .basicInfo-form {
      &:not(:last-of-type) {
        margin-bottom: 30px;
      }
      &-item {
        padding: 0 20px;
      }
    }
    .inlineCharts {
      width: 100%;
      height: 300px;
    }
    .totalNum {
      color: #d9001b;
      font-size: 15px;
      position: absolute;
      right: 15%;
      bottom: 4px;
      font-weight: 600;
      &.large {
        font-size: 20px;
      }
    }
    .price-item {
        width: 100%;
        padding: 8px 110px 8px 20px;
      .flexLayout();
      color: #66676de7;
      &.normal {
        padding-right: 230px;
      }
      span:first-of-type {
        min-width: 120px;
      }
      .name {
        color: #090b12;
        display: inline-block;
        min-width: 120px;
      }
    }
  }
}
</style>
